<template>
    <div class="container">
        <div>
            <b-card
            title="" sub-title="" class="shadow" id="fadein" style="background-color:whitesmoke"
            img-src="@/assets/6luMmcpOD2ir5Ky.jpg"
            img-alt="Image"
            img-top>
                <b-card-title>
                    <h1 class="display-5" ><i class="fa-solid fa-shield-halved"></i>战斗，心无旁骛</h1>
                </b-card-title>
                <b-card-text>
                    作为玩家自发成立的组织，BFEAC致力于为战地带来良好的游戏环境。
                    <router-link to="/about" style="align-items: flex-end;"><i class="fa-solid fa-circle-info"></i>&nbsp;了解更多</router-link>
                </b-card-text>
                
            </b-card>
        </div>
        <br />
        <div>
            <b-card class="shadow" id="fadein" style="background-color:whitesmoke">
                <b-card-title>
                    <h1 class="display-5" ><i class="fa-solid fa-shield-halved"></i>最新公告</h1>
                </b-card-title>

                <b-card-text>
                    <p>关于最近事情的重申，之前已于贴吧和官方群发过，此处为再次重申内容：</p>
                    <p>同步贴吧链接：<a href="https://tieba.baidu.com/p/9237895762" title="百度贴吧：关于EAC的闲唠（或者是声明）" target="_blank">百度贴吧：关于EAC的闲唠（或者是声明）</a>，欢迎顶贴，但是估计不会有回复。</p>

                    <h2>EAC的宗旨及相关说明</h2>
                    <p>自成立之初，EAC就明确提出了网站开发与日常网站运营分离的原则，这是基于过往经验得出的教训。具体说明如下：</p>

                    <h3>1. 关于EAC的职能范围</h3>
                    <ul>
                        <li><strong>EAC仅负责网站开发与运营</strong>，与任何形式的服务器管理工具无关。</li>
                        <li>任何标榜“EAC官方推出”的工具均为虚假工具。</li>
                        <li>踢人功能由各接入方自行运营，踢人权限和管理账号的配置由各接入方自主决定。EAC不会提供任何管理账号、权限等功能。</li>
                        <li>EAC与EA的交互接口仅限于：
                            <ul>
                                <li>查询信息</li>
                                <li>扫描并记录玩家历史ID</li>
                            </ul>
                        </li>
                    </ul>

                    <h3>2. 关于工具接入的政策</h3>
                    <ul>
                        <li><strong>EAC从不限制工具的接入</strong>，但前提是提交的工具需具备明确的功能设计。</li>
                        <li>如果提交申请的工具尚未完善且未投入使用即申请接入，这样只会影响工具本身及EAC的正常运营。</li>
                    </ul>

                    <h3>3. 关于Key的分配与管理情况</h3>
                    <p>目前EAC数据库内存在17个Key（作用为：调用如实锤列表，举报等接口的授权），其中：</p>
                    <ul>
                        <li>1个为测试用途；</li>
                        <li>1个用于数据判定机器人及战绩快照功能；</li>
                        <li>其余15个分配给各方工具。</li>
                    </ul>
                    <p>这些Key是否仍在被使用，EAC未进行详细跟踪。此外，EAC从未取消任何工具的Key权限，更不存在威胁取消的情况。但是若使用者不想使用，EAC也可帮助其关闭相应功能。</p>

                    <h3>4. 关于EAC的官方信息及代言</h3>
                    <p>EAC没有任何官方代言人。<strong>所有信息以EAC公告为准</strong>。若有人声称自己能代表EAC，直接回应“你谁？”即可。</p>

                    <h3>5. 关于EAC与22的关系</h3>
                    <p>EAC与22的合作关系体现在：</p>
                    <ul>
                        <li>EAC为22提供Key以进行举报及获取名单的功能。</li>
                    </ul>
                    <p>除此之外，再无其他技术或业务合作。EAC本身技术含量有限，和某些人说的没啥区别，仅提供几个简单的查询接口和一个普通大学生课程作业难度的网站，仅由几位开发人员负责维护。</p>

                    <h3>6. 关于近期事件的态度</h3>
                    <p>针对本次事件，EAC的态度如下：</p>
                    <ul>
                        <li><strong>EAC保持中立</strong>，继续正常提供接入服务（详见EAC官方群10月24日公告内容）。</li>
                        <li>另：EAC内部成员包括还在开服和提供暖服机服务的，本身完全是利益相关方，但是从网站功能本身与相应事件不相关，是否持有意见无法统一。</li>
                    </ul>
                    <p>EAC群组在22发布公告之前，从未讨论过限制暖服机等问题。如果有人散布“EAC与22联合限制”或者“出事了知道切割了”等言论，<strong>一切由您自行解读，我们不予置评</strong>。</p>
                    <p><strong>对于散布“22为EAC人员”等不实消息的事情，相关谣言自2023年1月就有，EAC在此奉劝，不要认为感觉自己知道点捕风捉影的事情，就当自己是半个局内人，可以无视事实散布谣言。</strong></p>

                    <hr>

                    <h3>致谢</h3>
                    <p>感谢拨冗阅读至此，您是否理解并接受由您自己决定。愿意深入了解的人自然会记住，不愿意的，也可能断章取义甚至视而不见。内容已尽，随缘。</p>
                </b-card-text>
            </b-card>
        </div>
        <br />
        <div>
            <b-card title="" sub-title="" class="shadow" id="fadein">
                <b-card-title>
                    <h1 class="display-5" ><i class="fa-solid fa-shield-halved"></i>踢出公示</h1>
                </b-card-title>
                <PageKickedLog />
            </b-card>
        </div>
    </div>
</template>

<script>
import PageKickedLog from "@/components/PageKickedLog";

export default {
    components: {
      PageKickedLog
    }
}


</script>